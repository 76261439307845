import { CollaboratorAccess, PermissionName } from '../../types/asset-permissions.types'
import { PermissionsTypes } from '../../../../types/flowcode'

export const ASSET_PERMISSIONS_BY_ACCESS_TYPE: Record<CollaboratorAccess, PermissionName> = {
  //Since we can't distiguish between the creator and the owner of a Flowcode on the backend right now, we choose the more general label of 'owner' when we see flowcode://asset/creator
  [PermissionsTypes.OWNER]: PermissionName.OWNER,
  [PermissionsTypes.CREATOR]: PermissionName.OWNER,
  [PermissionsTypes.EDITOR]: PermissionName.EDITOR,
  [PermissionsTypes.VIEWER]: PermissionName.VIEWER
}
