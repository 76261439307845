import { PermissionsTypes } from '../../../types/flowcode'

export interface AssetPermissionsInReduxStore {
  currentPermissions: AssetPermission[]
  draftPermissions: AssetPermission[]
}

export interface AssetPermission {
  entityId: string
  entityName: string
  entityType: number
  permissionName: PermissionName
}

export enum PermissionName {
  //currently we don't distinguish between owners and creators of codes. this may change in the future
  OWNER = 'flowcode://asset/creator',
  EDITOR = 'flowcode://asset/editor',
  VIEWER = 'flowcode://asset/viewer'
}

//asset identifiers are a collection of possible ids that can be used to identify an asset.
//based on these, we can create an asset id
export interface AssetIdentifiers {
  batchId?: string
  directoryId?: string
  isBatch: boolean
  isPageV2?: boolean
  pageId?: string
  pageTemplateId?: string
  subdomainId?: string
}

export enum AssetType {
  PAGE = 'page',
  PAGEV2 = 'page_v2',
  BATCH = 'batch',
  DIRECTORY = 'directory',
  PAGE_TEMPLATE = 'fp_template',
  SUBDOMAIN = 'subdomain'
}

//aliasing for readability elsewhere
export type CollaboratorAccess = PermissionsTypes
