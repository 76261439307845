import { DEFAULT_PAGE_ID } from '@dtx-company/true-common/src/constants/page'
import { checkPageOwnership } from '@dtx-company/inter-app/src/redux/slices/pageEditor/getPageObj'
import {
  getBatchPermissions,
  getDirectoryPermissions,
  getNameInitials,
  getPagePermissions,
  getRandomBackgroundImage
} from '../utils/permissions'
import {
  getFlowcodeSubdomainPermissions,
  getFlowpageSubdomainPermissions
} from '../components/Domains/DomainsV2/hooks/subdomainPermissions.hooks'
import { getPageTemplatePermissions } from '@dtx-company/inter-app/src/utils/permissions/pageTemplates'
import { permissionsNames } from '../constants/permissions'
import type { AllPageInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import type {
  CollaboratorsType,
  EntityPermissionsType,
  PermissionNameType
} from '@dtx-company/inter-app/src/types/permissions'
import type { FetchPermissionsResult } from './useGetPermissions.fetcher'

export async function fetchByPageId(
  pageId: string,
  currentPage?: AllPageInfoFragment | null
): Promise<FetchPermissionsResult> {
  if (pageId === DEFAULT_PAGE_ID) {
    return {
      isOwner: true,
      entityPermissions: [],
      existingCollaborators: [],
      ownerFullName: '',
      ownerEntityId: ''
    }
  } else {
    const [permissions, isOwner] = await Promise.all([
      getPagePermissions({ pageId }),
      checkPageOwnership({ pageId })
    ])
    const ownerFullName = currentPage?.owner?.displayName || currentPage?.owner?.email || ''
    const ownerEntityId = currentPage?.owner?.ithacaId ?? ''
    return {
      isOwner,
      ownerFullName,
      ownerEntityId,
      entityPermissions: permissions,
      existingCollaborators: [...getSelectedCollborators(permissions)]
    }
  }
}

export async function fetchByBatchId(
  batchId: string,
  token: string
): Promise<FetchPermissionsResult> {
  const batchPermissions = await getBatchPermissions(
    {
      batchId,
      scopePermissions: false
    },
    token
  )
  return {
    isOwner: batchPermissions?.batch?.batchOwner.isOwner,
    entityPermissions: batchPermissions?.batch?.entityPermissions,
    existingCollaborators: getSelectedCollborators(batchPermissions?.batch?.entityPermissions),
    ownerFullName: batchPermissions?.batch?.batchOwner.user.fullName ?? '',
    ownerEntityId: batchPermissions?.batch?.batchOwner.user.ithacaId ?? ''
  }
}

export async function fetchByDirectoryId(directoryId: string): Promise<FetchPermissionsResult> {
  const directoryPermissions = await getDirectoryPermissions({
    directoryId,
    scopePermissions: false
  })
  return {
    isOwner: directoryPermissions?.directory?.isOwner ?? false,
    entityPermissions: directoryPermissions.directory?.entityPermissions ?? [],
    existingCollaborators: getSelectedCollborators(
      directoryPermissions.directory?.entityPermissions
    ),
    ownerFullName: directoryPermissions.directory?.user.fullName ?? '',
    ownerEntityId: directoryPermissions.directory?.user.ithacaId ?? ''
  }
}

export async function fetchByPageTemplateId(
  pageTemplateId: string,
  token: string,
  myEntityId?: string
): Promise<FetchPermissionsResult> {
  const { getAssetDetails, template } = await getPageTemplatePermissions(pageTemplateId, token)
  const entityPermissions =
    getAssetDetails?.[0]?.entityPermissions?.map(entityPermission => ({
      ...entityPermission,
      entityName: entityPermission.entityName ?? ''
    })) ?? []
  return {
    isOwner: myEntityId === template?.owner?.ithacaId,
    entityPermissions,
    existingCollaborators: getSelectedCollborators(entityPermissions),
    ownerFullName: template?.owner?.displayName || template?.owner?.email || '',
    ownerEntityId: template?.owner?.ithacaId ?? ''
  }
}

export async function fetchBySubdomainId(
  subdomainId: string,
  isBatch: boolean
): Promise<FetchPermissionsResult> {
  const result = isBatch
    ? await getFlowcodeSubdomainPermissions(subdomainId)
    : await getFlowpageSubdomainPermissions(subdomainId)
  const entityPermissions = (result?.entityPermissions ?? [])
    .map(entityPerm => {
      if (entityPerm) {
        return {
          ...entityPerm,
          entityType: Number(entityPerm.entityType)
        }
      }
    })
    .filter(p => p !== undefined) as EntityPermissionsType[]

  return {
    isOwner: true,
    entityPermissions,
    existingCollaborators: getSelectedCollborators(entityPermissions),
    ownerFullName: '',
    ownerEntityId: ''
  }
}

export function getSelectedCollborators(
  entityPermissions: EntityPermissionsType[]
): CollaboratorsType[] {
  if (entityPermissions) {
    return entityPermissions.map(item => {
      const permissionName = item.permissionName as PermissionNameType
      return {
        name: item.entityName,
        entityId: item.entityId,
        entityType: item.entityType,
        icon: getRandomBackgroundImage(),
        access: permissionsNames[permissionName],
        initials: getNameInitials(item.entityName)
      }
    })
  }
  return []
}
